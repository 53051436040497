import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";

const topImage = require("../../assets/automate/home/automate_img_1.png");

const section_one = require("../../assets/automate/workflow_automation/workflow_automation_sec_3.png");
const section_two = require("../../assets/automate/home/automate_img_2.png");
const section_three = require("../../assets/automate/workflow_automation_sec_2.png");
const section_four = require("../../assets/automate/workflow_auto_4.png");

const featureContent = [
  {
    header: "Launch workflow automations via app triggers",
    content:
      "Connect your apps and launch workflows via event, polling-based app triggers or webhooks. Workativ requires no coding to add triggers or webhooks to automate workflows between your favorite apps.",
    url: null,
    link: null,
    image: section_one,
  },
  {
    header: "Transform your workplace with smart workflow recipes",
    content:
      "Prebuilt workflow automations help teams to hit the ground running in minutes. Use from our 50+ app integrations, 400+ actions, and 1000s of ready-to-use workflows for multiple usecase such as onboarding, incident automation, user provisioning, access management, ticket automation, HR automation, IT automation and much more from the marketplace. ",
    // url: "/automate/triggers",
    // link: "Learn more",
    image: section_two,
  },
  {
    header: "Connect with popular apps",
    content:
      "Get more out of workflow automation with one-click app integrations that’s intuitive and enables you to go-live in record time, with zero coding.   ",
    content1: "Yeah, you heard that right, not a single line of code.",
    // url: "/assistant/workflow-automation/",
    // link: "Learn more",
    image: section_three,
  },
  {
    header: "Integrate apps and add workflows to your own chatbot",
    content:
      "Workativ enables you to add workflow automations to your favorite chatbot platforms like IBM Watson, Dialogflow, Amazon Lex, Microsoft Luis etc. or custom chatbots using our webhooks. Time to take your chatbot to the next level by adding workflow automation.",
    // url: "/automate/value-add",
    // link: "Learn more on Workativ’s value add",
    image: section_four,
  },
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Workflow Automation Software | Add workflows to your chatbot or simply automate tasks between apps"
        description="Get more from an automation platform that works on multiple conversational platforms, provides downloadable workflows, has hybrid connectors and provides value at competitive prices"
        keywords={["Workflow Automation"]}
        ogImage={topImage}
      />
      <Container>
        <section className="automate_homepage workflow_autoamtion automate_product">
          <Layout
            backgroundColor={"bg_header_automation"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <RegisterSection
              altImage={
                "Deliver workflow automations in a jiff"
              }
              rightImage={topImage}
              backgroundColor={"bg_header_automation"}
            >
             
              <RegisterSection.FormWithContent product="automate">
                <RegisterSection.MainHeader>
                Deliver workflow automations in a jiff
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                Say goodbye to expensive and bulky solutions. Trust your team to create workflows that scale. No coding or developer needed.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>{" "}
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="support_chatbot autoamte_chatbot">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.header}
                    >
                      <RightImageWithContentFeature.Header>
                        <h2>{feature.header}</h2>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                        {feature.content1 && <p className="second_p">{feature.content1}</p>}
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                    {feature.content1 && <p className="second_p">{feature.content1}</p>}
                  </LeftImageWithContent.SubHeader>

                  <LeftImageWithContent.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
              <div className="automate_homepage">
          <RequestForm product="automate" content="We need your feedback, inputs, and experience to help us build a great product!" AutomateCTA automateContent autoamteButton automateName isFooterForm={true} />
          </div>
          </Layout>
        </section>
      </Container>
    </>
  );
}
